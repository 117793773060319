import axios from "axios"
import { serverMode } from "./config";

const localhost = 'http://localhost:8800/'
const production = 'https://teamcgs.io/'

export const requestUrl = axios.create({
    baseURL: (serverMode ? production : localhost),
    headers: {
        'Content-Type': 'application/json',
    },
})

// export const serviceUrl = serverMode ? production : localhost

export default requestUrl
